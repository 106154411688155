<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="filter-wrapper" v-if="subjectList.length">
            <filter-tab title="学科" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="meeting-item" :key="index" v-for="(item,index) in videoMeetingList" @click="handleNavigateEvent(item)">
                        <!-- @click="meetJumpDetail(item.href,item.id)" -->
                        <across-media-item :item="item">
                            <template v-slot:bottom>
                                <span v-if="item.start_time" class="color999 fs14"><i
                                        class="med med-31shijian mr5"></i>{{item.start_time.substr(0,10)}}
                                        <span v-if="item.end_time">至 {{item.end_time.substr(0,10)}}</span>
                                    </span>
                            </template>
                        </across-media-item>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!videoMeetingList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="videoTotal > 0">
                    <a-pagination :total="videoTotal" v-model:current="videoParams.page" :page-size="videoParams.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import acrossMediaItem from '@/components/across-media-item';
import filterTab from '@/components/filter-tab.vue';
import { jsonFormat } from '@/utils/jsonFormat';
import { meetJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store'

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, hotTopicItem, acrossMediaItem, filterTab
    },
    data() {
        return {
            navList: orgDetailList(storage.get('commiittee_id')),//顶部菜单
            navCurrentId: 4,//当前页面菜单对应下标
            videoParams: {
                committee_id_search: storage.get('commiittee_id'),
                limit: 10,
                page: 1,
            },
            videoTotal: 0,
            videoMeetingList: [],
            subjectIndex: -1,
            subjectList: [],
            loading: true,
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '最新资讯', // 标题
                    moreLink:'/organization/org-infomation?committee_id='+ storage.get('commiittee_id'),
                    detailLink:'/organization/org-info-detail',
                    data: {
                        page: 1,
                        limit: 5,
                        committee_id:storage.get('commiittee_id'),
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-meeting?committee_id='+ storage.get('commiittee_id'),
                    data: {
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        committee_id: storage.get('commiittee_id'),
                    },   // 参数条件
                },
                live: { //相关直播
                    show: true, // 是否显示
                    methods:'get',
                    title: '相关直播', // 标题
                    moreLink:'/organization/org-live?committee_id='+ storage.get('commiittee_id'),
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id: storage.get('commiittee_id'),
                        no_end_time: 1,
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.pageInit()
    },
    methods: {
        meetJumpDetail,
        getVideoMeetingList() {
            this.request.get('GetOpenVideoMeetingLive', this.videoParams).then(res => {
                let meetbackObj = {
                    news_title: 'title',
                    id:'type_id',
                    img:'card_img'
                }
                this.videoTotal = parseInt(res.data.count)
                this.videoMeetingList = jsonFormat(res.data.list, meetbackObj)
            })
        },
        // 学科切换
        handleFilterEvent(key,e){
            key && (this[key] = e);
            if(this.subjectIndex != -1){
                this.videoParams.subject_id = this.subjectList[this.subjectIndex].subject_ids
                this.videoParams.page = 1
                this.getVideoMeetingList()
            }else{
                delete this.videoParams.subject_id
                this.getVideoMeetingList()
            }
        },
        pageInit() {
            this.loading = true
            let reqArr = [
                this.request.get('GetOpenVideoMeetingLive', this.videoParams),
                this.$store.dispatch('getSubjectList')
            ]
            Promise.all(reqArr).then(res => {
                // 回放列表
                this.videoTotal = parseInt(res[0].data.count)
                let meetbackObj = {
                    news_title: 'title',
                    id:'type_id',
                    img:'card_img'
                }
                this.videoMeetingList = jsonFormat(res[0].data.list, meetbackObj)

                // 全部学科
                let subjectObj = {
                    name:'operate_name',
                }
                this.subjectList = jsonFormat(res[1].data,subjectObj)
                this.loading = false
            }).catch(err=>{
                console.log(err)
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.videoParams.page = page
            this.getVideoMeetingList()
        },
        // 跳转
        handleNavigateEvent(item){
            this.$router.push({
                path: '/special-detail',
                query: {
                    type_id:item.type_id,
                    type:item.type
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.filter-wrapper {
    width: 1240px;
    margin: 0 auto 20px;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.homepage-list {
    margin-right: 30px;
    min-height: 600px;
    .interview-list {
        min-height: 500px;
    }

    .bb {
        border-bottom: 1px solid #D8D8D8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }

    .live-item {
        margin-right: 30px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.homepage-right {
    width: 400px;
}
@media screen and (max-width:768px){
    .box{
        .filter-wrapper {
            padding: 2px 0;
            border-radius: 0;
            position: fixed;
            top: 50px;
            background: #f2f4fa;
            z-index: 999999;
        }
        .homepage-content{
            width: 100%;
            margin-top: 50px;
            display: block;
            padding: 20px 10px 0;
            .homepage-list{
                width: 100%;
                margin-right: 0;
                min-height: 0;
                /deep/.ant-empty{
                    margin-top: 0!important;
                }
            }
            .homepage-right{
                width: 100%;
                margin-top: 50px;
            }
        }
    }
}
</style>